export default {
  gray: "Gris",
  green: "Verte",
  emerald: "Vert émeraude",
  lime: "Vert citron",
  slate: "Gris ardoise",
  zinc: "Gris zinc",
  cyan: "Bleu cyan",
  sky: "Bleu ciel",
  blue: "Bleu",
  indigo: "Bleu indigo",
  violet: "Lavande",
  purple: "Violet",
  fuchsia: "Rose fuschia",
  rose: "Rose",
  orange: "Orange",
  yellow: "Jaune",
  pink: "Incarnat",
  white: "Blanc",
  teal: "Bleu canard",
  red: "Rouge",
}
